<template>
  <section
  >
    <el-row>
      <el-col class="secondcol" :md="18" :sm="18" :xs="24">
        <templateSubmittedDataTable
          v-if="showDataofTemplates"
          :isAEntityTemplateData="isAEntityTemplateData"
          :configurableDocumentId.sync="configId"
          :configurableDocumentData.sync="this.viewDataOfTemplates"
          :entityId="this.entityId ? this.entityId : this.entityIdForTable"
          :entityDataId="this.entityDataId ? this.entityDataId : this.entityDataIdForTable"
        />
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
const templateSubmittedDataTable = () =>
  import("@/components/templates/templateSubmittedData.vue");

export default {
  name: "templates-PreviewTemplate",
  props: ["entityId", "entityDataId","entityIdForTable","entityDataIdForTable","configurableId"],
  data() {
    return {
      entityDocumentTemplates: [],
      array: [],
      documentTemplates: [],
      configureLoading: false,
      LoadingText: "",
      is_document_data: false,
      showDataofTemplates: false,
      configurableDocumentId: null,
      isAEntityTemplateData: false,
      configurableDocumentData: null,
      templates: [],
      configurableDocId:this.configurableId,
      configId: "",
      viewDataOfTemplates: [],
      templatesCount: null,
      search_string: "",
      total: 0,
      currentPage: 1,
      myArray:[],
      pageSize: 5,
      page: 1,
      order_by: null,
    };
  },
  components: {
    templateSubmittedDataTable,
  },
  created() {
    bus.$emit("templates-length", {
      templatesCount: this.templates.length,
    });
  },
  async mounted() {
    this.templateDocumentsSideMenu()
    await this.fetchEntityDocumets();
    
  },
  computed: {
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("entities", [
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getTemplatesFieldsDataFromEntitiesData",
      "getTemplatesFieldsDataFromEntitiesStatus",
    ]),
    getClass() {
      return (data) => {
        if (
          this.$route.query.documentTemplateId == data.configurable_document_id
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
  },
  methods: {
    async fetchEntityDocumets() {
      try {
        this.configureLoading = true;
        this.LoadingText = "Fetching Entity Document Templates...";
        this.entityDocumentTemplates = [];
        let params = {
          entity_id: this.entityId ? this.entityId : this.entityIdForTable,
          entity_data_id: this.entityDataId ? this.entityDataId : this.entityDataIdForTable  ,
        };
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch("entities/fetchEntitiesDocuments", params);
        setTimeout(() => {
          this.configureLoading = false;
        }, 600);
        if (this.getEntityDocumentsDataStatus) {
          this.entityDocumentTemplates = this.getEntityDocumentsData;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in fetching entity documents",
          });
        }

        if(this.$route.query && this.$route.query.documentTemplateId){
          await this.fetchTemplates();
          this.showDataofTemplates = true;     
          this.configId = this.configurableDocId ? this.configurableDocId : this.$route.query.documentTemplateId
          this.configurableDocumentData = this.getConfigurableDocumentDataById
          this.entityId = this.$route.params.entity_id
          this.entityDataId = this.$route.query.dataId
          this.isAEntityTemplateData = true;
        }
      } catch (err) {
        console.log("fetchEntityDocumets",err);
      }
    },

    async templateDocumentsSideMenu() {
      this.configureLoading = true;
      this.LoadingText = "Fetching Document Data...";
      this.showDataofTemplates = false;
      this.configureLoading = false;
      this.$router.push({
        path: `/entity_data_view/${this.$route.params.entity_id}/${
          this.$route.params.template_code
        }?dataId=${this.entityDataId || ""}&activeStep=${
          this.$route.query.activeStep
        }&documentTemplateId=${this.configurableDocId}`,
      });
      this.configId = this.configurableDocId ? this.configurableDocId : this.$route.query.documentTemplateId;
    },
    async fetchTemplates() {
      try {
        this.configureLoading = true;
        this.LoadingText = "Fetching Template Documents Data...";
        let configurable_document_id = this.configurableDocId ? this.configurableDocId : this.$route.query.documentTemplateId;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: configurable_document_id,
          }
        );
        this.configureLoading = false;
        this.viewDataOfTemplates = this.getConfigurableDocumentDataById;
      } catch (err) {
        console.log("fetchTemplates",err);
      }
    },
    async fetchingSubmittedTemplatesData(data) {
      await this.fetchTemplates();
      this.configureLoading = true;
      this.LoadingText = "Fetching Template Documents Data...";
      this.showDataofTemplates = true;
      if (data && data.configurable_document_id || this.configurableDocId) {
        this.isAEntityTemplateData = true;
        this.configurableDocumentId = data.configurable_document_id ? data.configurable_document_id : this.configurableDocId;
        this.configurableDocumentData = this.viewDataOfTemplates;
        bus.$emit("document-change", {
          configurableDocumentId: this.configurableDocId ? this.configurableDocId : data.configurable_document_id ? data.configurable_document_id : this.$route.query.documentTemplateId,
          configurableDocumentData: this.getConfigurableDocumentDataById,
          isAEntityTemplateData: this.isAEntityTemplateData,
        });
      }
      this.configureLoading = false;
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchEntityDocumets();
    },
    "$route.query.documentTemplateId"(step) {
      let data = 
        this.entityDocumentTemplates &&
        this.entityDocumentTemplates.length &&
        this.entityDocumentTemplates.filter((el) => {
          if (el && el.configurable_document_id === step) {
            this.isAEntityTemplateData = true;
            this.myArray.push(el)
            return el;
          }
        });
      if (data && data.length) {
        this.isAEntityTemplateData = true;
        this.fetchingSubmittedTemplatesData(data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group {
  margin-top: 30px;
  width: 255px;
  border-left: 2px #9299b8;
  border-top: 2px #9299b8;
  border-bottom: 1px ridge;
  .list-group-item {
    margin-right: -20px;

    margin-left: -35px;
    padding: 0em;
    border: none;
    color: #9299b8;
    &.active {
      color: #f754a2;
      border-left: 3px solid #f754a2;
    }
  }
}
.secondcol {
  width: 100%;
}
.sidemenu {
  height: 450px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-bottom: 2px solid #f5f5f5;
  border-right: 1px ridge;
  border-left: 2px solid #f5f5f5;
  border-top: 2px solid #f5f5f5;
}
.sidemenu::-webkit-scrollbar {
  width: 0.4em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
</style>
<style>
.el-table .cell{
  word-break: break-word;
}
</style>